<template>
  <moe-page title="新增运费模版">
    <moe-form class="default-form" ref="freightForm" :showBack="false" :showClose="true" :model="freightParams" :rules="rules">
      <div class="df fdc w-500 margin-0-auto">
        <el-form-item label="模板名称" prop="name">
          <el-input v-model.trim="freightParams.name" placeholder="请输入模板名称" maxlength="50" clearable />
        </el-form-item>

        <el-form-item label="计价方式" prop="method">
          <moe-radio-group :disabled="IS_EDIT" :radioBoxList="$moe_data.freightMethodList" v-model="freightParams.method"></moe-radio-group>
        </el-form-item>

        <el-form-item label="供应商" prop="vendorId">
          <el-select :disabled="IS_EDIT" v-model="freightParams.vendorId" filterable placeholder="请选择供应商" clearable>
            <el-option
              v-for="(item, index) in supplierList"
              :key="index"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <!-- <el-form-item label="">
          <el-checkbox :border="true" v-model="freightParams.isDefault">设为默认模板</el-checkbox>
        </el-form-item> -->
      </div>

      <el-form-item label-width="0" label="">
        <div style="height: 1000px;min-height: 1000px;" class="df fdc">
          <moe-table :data="freightParams.configList" ref="freightTplTableRef" url="" :params="{ pageNum: 1 }" :numberShow="false" :mode="false" :key="itemKey">
            <div class="mb-20" slot="tool">
              <el-form-item label-width="0" label="">
                <el-button type="primary" icon="el-icon-circle-plus-outline" @click="handleAdd()">指定可配送区域</el-button>
              </el-form-item>
            </div>

            <el-table-column label="指定配送区域(未选地区默认包邮)" min-width="200" :show-overflow-tooltip="true">
              <template slot-scope="{ row, $index }">
                {{ formatterAreas(row, $index) }}
              </template>
            </el-table-column>

            <el-table-column :label="`${freightParams.method === 'PIECE' ? '首件（个）' : '首重（KG）'}`" width="200" >
              <div class="mt-30 mb-30" slot-scope="{ row, $index }">
                <el-form-item class="mt-30 mb-30" :prop="`configList.${$index}.firstCondition`" :rules="rules.firstCondition">
                  <template v-if="freightParams.method === 'PIECE'">
                    <el-input
                      :value="row.firstCondition"
                      @input="(value) => row.firstCondition = $moe_formatter.formatterInteger(value)"
                      placeholder="请输入"
                      maxlength="9"
                      clearable />
                  </template>
                  <template v-else-if="freightParams.method === 'WEIGHT'">
                    <el-input
                      :value="row.firstCondition"
                      @input="(value) => row.firstCondition = $moe_formatter.formatterWeight(value)"
                      placeholder="请输入"
                      maxlength="9"
                      clearable />
                  </template>
                </el-form-item>
              </div>
            </el-table-column>

            <el-table-column label="运费（元）" width="200">
              <div class="mt-30 mb-30" slot-scope="{ row, $index }">
                <el-form-item :prop="`configList.${$index}.firstPrice`" :rules="rules.firstPrice">
                  <el-input
                    :value="row.firstPrice"
                    @input="(value) => row.firstPrice = $moe_formatter.formatterMoney(value)"
                    placeholder="请输入"
                    maxlength="9"
                    clearable />
                </el-form-item>
              </div>
            </el-table-column>

            <el-table-column :label="`${freightParams.method === 'PIECE' ? '续件（个）' : '续重（KG）'}`" width="200">
              <div class="mt-30 mb-30" slot-scope="{ row, $index }">
                <el-form-item class="mt-30 mb-30" :prop="`configList.${$index}.continueCondition`" :rules="rules.continueCondition">
                  <template v-if="freightParams.method === 'PIECE'">
                    <el-input
                      :value="row.continueCondition"
                      @input="(value) => row.continueCondition = $moe_formatter.formatterInteger(value)"
                      placeholder="请输入"
                      maxlength="9"
                      clearable />
                  </template>
                  <template v-else-if="freightParams.method === 'WEIGHT'">
                    <el-input
                      :value="row.continueCondition"
                      @input="(value) => row.continueCondition = $moe_formatter.formatterWeight(value)"
                      placeholder="请输入"
                      maxlength="9"
                      clearable />
                  </template>
                </el-form-item>
              </div>
            </el-table-column>

            <el-table-column label="续费（元）" width="200">
              <div class="mt-30 mb-30" slot-scope="{ row, $index }">
                <el-form-item class="mt-30 mb-30" :prop="`configList.${$index}.continuePrice`" :rules="rules.continuePrice">
                  <el-input
                    :value="row.continuePrice"
                    @input="(value) => row.continuePrice = $moe_formatter.formatterMoney(value)"
                    placeholder="请输入"
                    maxlength="9"
                    clearable />
                </el-form-item>
              </div>
            </el-table-column>

            <el-table-column label="操作" fixed="right" min-width="250">
              <div class="moe-table_btns" slot-scope="{ row, $index }">
                <el-button type="primary" size="mini" icon="el-icon-edit" @click="handleEdit(row, $index)">编辑</el-button>
                <el-button type="danger" size="mini" icon="el-icon-delete" @click="handleDelete(row, $index)">删除</el-button>
              </div>
            </el-table-column>
          </moe-table>
        </div>
      </el-form-item>

      <template slot="tool">
        <el-button icon="el-icon-upload2" type="primary" :loading="freightLoad" @click="handleSubmit()">{{ freightLoad ? '提交中' : '提交' }}</el-button>
      </template>
    </moe-form>

    <moe-dialog :show="showDialog" title="选择配送区域" width="70%" @close="handleDialogClose()" :autoHeight="true" :closeOnClickModal="false">
      <div class="dialog_content overflow-h overflow-y-auto" style="height: 450px;">
        <div class="ml-50 mr-50">
          <div class="df fdc mb-30" v-for="(item, index) in bigAreasList" :key="index">
            <div class="mb-20">
              <el-checkbox
                :indeterminate="item.selectBigAreaIsIndeterminate"
                :border="true"
                v-model="item.selectBigAreaValue"
                :trueLabel="item.value"
                falseLabel=""
                @change="(value) => changeCheckBox('selectBigAreaValue', value, item)">
                {{ item.label }}
              </el-checkbox>
            </div>
            <div class="dg dg3">
              <div class="mb-30 mr-20" v-for="(areaItem, areaIndex) in item.areasList" :key="areaIndex">
                <div class="mb-10">
                  <el-checkbox
                    :indeterminate="areaItem.selectAreaValueIsIndeterminate"
                    v-model="areaItem.selectAreaValue"
                    :trueLabel="areaItem.value"
                    falseLabel=""
                    @change="(value) => changeCheckBox('selectAreaValue', value, item, areaItem)">
                    {{ areaItem.label }}
                  </el-checkbox>
                </div>
                <moe-cascader :multiple="true" placeholder="选择地区" v-model="areaItem.selectValue" :options="areaItem.children" @change="(value) => changeCascader(value, item, areaItem)"></moe-cascader>
              </div>
            </div>
          </div>
        </div>
      </div>

      <template slot="footer">
        <div class="df jcsb">
          <div>
            <el-checkbox :indeterminate="isIndeterminate" :border="true" v-model="selectAll" @change="(value) => changeCheckBox('selectAll', value)">全选</el-checkbox>
          </div>
          <div>
            <el-button @click="handleDialogClose()">取消</el-button>
            <el-button type="primary" @click="handleConfirm()">确定</el-button>
          </div>
        </div>
      </template>
    </moe-dialog>
  </moe-page>
</template>

<script>
export default {
  name: 'FreightManageAdd',
  computed: {
    IS_EDIT() {
      if (this.freightParams.id) {
        return true
      } else {
        return false
      }
    }
  },
  data() {
    const verify = this.$moe_verify.verifyForm;
    const checkFirstCondition = (rule, value, callback) => {
      if (!value) {
        callback(new Error(`请输入首级条件`));
      } else {
        // let index = rule.fullField.split('.')[1];
        let { method } = this.freightParams
        if (method === 'WEIGHT' && this.$moe_math.mathLessThanOrEqualTo(value, 0)) {
          callback(new Error(`重量必须大于0KG`));
        } else if(method === 'PIECE' && String(value).indexOf('.') > -1 || this.$moe_math.mathLessThanOrEqualTo(value, 0)) {
          callback(new Error(`数量必须为大于0的整数`));
        } else {
          callback();
        }
      }
    }
    const checkContinueCondition = (rule, value, callback) => {
      if (!value) {
        callback(new Error(`请输入续级条件`));
      } else {
        // let index = rule.fullField.split('.')[1];
        let { method } = this.freightParams
        if (method === 'WEIGHT' && this.$moe_math.mathLessThanOrEqualTo(value, 0)) {
          callback(new Error(`重量必须大于0KG`));
        } else if (method === 'PIECE' && String(value).indexOf('.') > -1 || this.$moe_math.mathLessThanOrEqualTo(value, 0)) {
          callback(new Error(`数量必须为大于0的整数`));
        } else {
          callback();
        }
      }
    }
    return {
      freightParams: {
        id: '',
        name: '',
        method: 'PIECE',
        vendorId: '',
        configList: [],
        // isDefault: false,
      },
      freightLoad: false,
      selectAll: false,
      isIndeterminate: false,
      supplierList: [],
      bigAreasList: [],
      areasList: [],
      selectIndex: null,
      showDialog: false,
      rules: {
        name: verify.isEmpty('请输入模板名称'),
        method: verify.isEmpty('请选择计价方式'),
        vendorId: verify.isEmpty('请选择供应商'),
        firstCondition: [{ required: true, validator: checkFirstCondition, trigger: ['blur', 'change'] }],
        firstPrice: verify.isEmpty('请输入首级价格'),
        continueCondition: [{ required: true, validator: checkContinueCondition, trigger: ['blur', 'change'] }],
        continuePrice: verify.isEmpty('请输入续级条件'),
      },
      oldTableData: [],
      itemKey: Math.random(),
    }
  },
  methods: {
    formatterAreas({ areaList }, index) {

      if (this.oldTableData.length && this.oldTableData[index]) {
        return this.oldTableData[index]
      }

      let areaNameList = [];
      areaList.forEach(({ provinceId, cityIdList }) => {
        let cityNameList = [];

        cityIdList.forEach((code) => {
          this.bigAreasList.forEach(({ areasList }) => {
            areasList.forEach(({ children }) => {
              children.forEach(({ value, label }) => {
                if (String(value) === String(code)) {
                  cityNameList.push(label);
                }
              })
            })
          })
        })

        this.bigAreasList.forEach(({ areasList }) => {
          areasList.forEach(({ label, value }) => {
            if (String(value) === String(provinceId)) {
              areaNameList.push({
                label,
                cityList: cityNameList
              })
            }
          })
        })
      })

      const resultList = areaNameList.map(({ label, cityList }) => {
        return `${label}: ${JSON.stringify(cityList)}`
      }).join('、');

      if (resultList.length) {
        this.oldTableData[index] = resultList;
      }

      return resultList;
    },
    formatter() {

      // 全选操作
      let checkedCount = this.bigAreasList.length;

      this.bigAreasList.forEach((item) => {
        // 省级操作
        item.areasList.forEach((areaItem) => {
          let areaItemCheckedCount = areaItem.selectValue.length;
          let areaItemAllSelectLength = areaItem.children.length;
          areaItem.selectAreaValueIsIndeterminate = areaItemCheckedCount > 0 && areaItemCheckedCount < areaItemAllSelectLength;
        })

        // 大区操作
        let itemCheckedCount = item.areasList.filter(({ selectValue, children }) => !selectValue.length || selectValue.length < children.length).length;
        let itemAllSelectLength = item.areasList.length;
        item.selectBigAreaIsIndeterminate = itemCheckedCount > 0 && itemCheckedCount < itemAllSelectLength;

        if (item.selectBigAreaIsIndeterminate || itemCheckedCount > 0) {
          checkedCount -= 1;
        }

        // 大区取消打勾
        let itemSelectAll = item.areasList.filter(({ selectAreaValue }) => selectAreaValue).length;
        if (itemSelectAll === 0 && item.selectBigAreaValue) {
          item.selectBigAreaValue = '';
        }
      })

      // 全选操作
      let allSelectLength = this.bigAreasList.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < allSelectLength;

      // 二次查询全选操作是否能高亮
      if (!this.isIndeterminate && this.bigAreasList.filter(({ selectBigAreaIsIndeterminate }) => selectBigAreaIsIndeterminate).length) {
        this.isIndeterminate = true;
      }

      if (checkedCount === 0 && this.selectAll) {
        this.selectAll = false;
      }
      if (checkedCount === this.bigAreasList.length && !this.selectAll) {
        this.selectAll = true;
      }
    },
    changeCascader(value, item, areaItem) {
      if (value.length) {
        if (!item.selectBigAreaValue) {
          item.selectBigAreaValue = item.value;
        }
        if (!areaItem.selectAreaValue) {
          areaItem.selectAreaValue = areaItem.value;
        }
      } else {
        areaItem.selectAreaValue = '';
      }

      this.formatter();
    },
    changeCheckBox(type, value, item, areaItem) {
      switch (type) {
        case 'selectAll':
          if (this.selectAll) {
            this.bigAreasList.forEach((bigAreasListItem) => {
              bigAreasListItem.selectBigAreaValue = bigAreasListItem.value
              bigAreasListItem.areasList.forEach((areasListItem) => {
                areasListItem.selectAreaValue = areasListItem.value;
                areasListItem.selectValue = areasListItem.children.map(({ value }) => value);
              })
            })
          } else {
            this.bigAreasList.forEach((bigAreasListItem) => {
              bigAreasListItem.selectBigAreaValue = '';
              bigAreasListItem.areasList.forEach((areasListItem) => {
                areasListItem.selectAreaValue = '';
                areasListItem.selectValue = [];
              })
            })
          }
          break;
        case 'selectBigAreaValue':
          if (value) {
            item.areasList.forEach((areasListItem) => {
              areasListItem.selectAreaValue = areasListItem.value;
              areasListItem.selectValue = areasListItem.children.map(({ value }) => value);
            })
          } else {
            item.areasList.forEach((areasListItem) => {
              areasListItem.selectAreaValue = '';
              areasListItem.selectValue = [];
            })
          }
          break;
        case 'selectAreaValue':
          if (value) {
            item.selectBigAreaValue = item.value;
            areaItem.selectValue = areaItem.children.map(({ value }) => value);
          } else {
            areaItem.selectValue = [];
            let otherSelectListLength = this.bigAreasList.find(({ value }) => value === item.value).areasList.filter(({ selectValue }) => selectValue.length).length
            if (otherSelectListLength === 0) {
              item.selectBigAreaValue = '';
            }
          }
          break;
      }

      this.formatter();
    },
    handleAdd() {
      this.showDialog = true;
    },
    handleEdit({ areaList }, index) {
      areaList.forEach(({ provinceId, cityIdList }) => {
        this.bigAreasList.forEach((item) => {
          item.areasList.forEach((areaItem) => {
            if (String(areaItem.value) === String(provinceId)) {
              item.selectBigAreaValue = item.value;
              areaItem.selectAreaValue = String(provinceId);

              areaItem.selectValue = cityIdList.map((code) => {
                return String(code);
              })
            }
          })
        })
      })
      //记录是编辑操作
      this.selectIndex = index;
      this.formatter();
      this.showDialog = true;
    },
    handleDelete(item, index) {
      this.$moe_layer.confirm(`您确定要删除当前选项吗？`, () => {
        this.freightParams.configList.splice(index, 1);
        this.oldTableData.splice(index, 1);
      });
    },
    handleConfirm() {
      let areaList = this.bigAreasList.filter(({ selectBigAreaValue }) => selectBigAreaValue).map(({ areasList }) => {
        return areasList.filter(({ selectAreaValue }) => selectAreaValue).map(({ selectValue, selectAreaValue, label }) => {
          return {
            cityIdList: selectValue.flat(),
            provinceId: `${selectAreaValue}`,
          }
        })
      }).flat();

      if (!areaList.length) {
        return this.$moe_msg.warning('请选择选择配送区域 !');
      }

      if (this.selectIndex === null) {
        this.freightParams.configList.push({
          areaList,
          firstCondition: '',
          firstPrice: '',
          continueCondition: '',
          continuePrice: '',
        });
      } else {
        this.freightParams.configList[this.selectIndex].areaList = areaList;
        this.oldTableData[this.selectIndex] = '';
      }

      this.handleDialogClose();
    },
    handleDialogClose() {
      this.selectIndex = null;
      this.selectAll = false;
      this.isIndeterminate = false;
      this.bigAreasList.forEach((item) => {
        item.selectBigAreaIsIndeterminate = false;
        item.selectBigAreaValue = '';

        item.areasList.forEach((areaItem) => {
          areaItem.selectAreaValueIsIndeterminate = false;
          areaItem.selectAreaValue = '';
          areaItem.selectValue = [];
        })
      })
      this.showDialog = false;
    },
    handleSubmit() {
      this.$refs['freightForm'].validate(() => {
        this.freightLoad = true;
        if (this.freightParams.id) {
          this.req_updateFreightTpl();
        } else {
          this.req_addFreightTpl();
        }
      })
    },
    /** 修改运费模板 */
    req_updateFreightTpl() {
        this.$moe_api.FREIGHTTPL_API.updateFreightTpl(this.freightParams).then((data) => {
        if (data.code === 200) {
          this.$moe_msg.success('修改运费模版成功', {
            completionHandler: () => {
              this.$moe_coordinator.navigateBack();
            }
          });
        } else {
          this.$moe_msg.error(data.message);
        }
      }).finally(() => {
        this.freightLoad = false;
      })
    },
    /** 创建运费模板 */
    req_addFreightTpl() {
      this.$moe_api.FREIGHTTPL_API.addFreightTpl(this.freightParams).then((data) => {
        if (data.code === 200) {
          this.$moe_msg.success('新增运费模版成功', {
            completionHandler: () => {
              this.$moe_coordinator.navigateBack();
            }
          });
        } else {
          this.$moe_msg.error(data.message);
        }
      }).finally(() => {
        this.freightLoad = false;
      })
    },
    /* 获取运费模板详情 */
    getFreightTplDetail(id) {
      this.$moe_api.FREIGHTTPL_API.getFreightTplDetail({ id }).then((data) => {
        if (data.code === 200) {
          let { id, name, method, vendorId, configList } = data.result;
          this.freightParams = {
            id,
            name,
            method,
            vendorId,
            // isDefault,
            configList,
          }
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    },
    /** 获取地址列表集合 */
    req_getAreasJsonList() {
      this.$moe_api.SYSTEM_API.getAreasJsonList().then((data) => {
        this.areasList = data;
        this.req_getAreasBigAreas(data);
      })
    },
    /** 获取大区列表 */
    req_getAreasBigAreas(areasList) {
      this.$moe_api.SYSTEM_API.getAreasBigAreas().then((data) => {
        if (data.code === 200) {
          this.bigAreasList = data.result.map(({ name, code, provinceCodes }) => {
            return {
              label: name,
              value: code,
              selectBigAreaIsIndeterminate: false,
              selectBigAreaValue: '',
              areasList: provinceCodes.map((provinceCode) => {
                let findItem = areasList && areasList.length ? areasList.find(({ code }) => provinceCode === code) : '';
                if (!findItem) return true
                return {
                  label: findItem.name,
                  value: findItem.code,
                  selectAreaValueIsIndeterminate: false,
                  selectAreaValue: '',
                  selectValue: [],
                  children: findItem.children.map(({ code, name }) => {
                    return {
                      label: name,
                      value: code,
                    }
                  })
                }
              })
            }
          });

          if (this.$route.query.id) {
            this.getFreightTplDetail(this.$route.query.id);
          }
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    },
    /** 分页查询供应商列表 */
    req_getVendorList() {
      this.$moe_api.SUPPLIER_API.getVendorList({ pageNum: 1, pageSize: 999, status: true }).then((data) => {
        if (data.code === 200) {
          this.supplierList = data.result.list.map(({ name, id }) => {
            return {
              label: name,
              value: id
            }
          });
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    },
  },
  mounted() {
    this.req_getVendorList();
    this.req_getAreasJsonList();
  }
}
</script>

<style lang="scss">

</style>